import Image from 'next/image';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { ImageFragment, ResponsiveImage } from '@hultafors/eripiowear/types';

import { DesktopImage, MobileImage, TabletImage } from './hero-image.styled';

export interface HeroImageProps {
  desktop?: ImageFragment;
  mobile?: ImageFragment;
  tablet?: ImageFragment;
  priority?: boolean;
  breakpointTablet?: Breakpoint;
  breakpointDesktop?: Breakpoint;
}

export const HeroImage: React.FC<HeroImageProps> = ({
  mobile,
  tablet,
  desktop,
  priority,
  breakpointTablet: breakpointTabletKey = 'tablet',
  breakpointDesktop: breakpointDesktopKey = 'desktop',
}) => {
  const breakpointTablet = breakpoints[breakpointTabletKey];
  const breakpointDesktop = breakpoints[breakpointDesktopKey];
  const ImageElement: React.FC<{
    data: ResponsiveImage;
    hasPriority?: boolean;
    alt: string;
  }> = ({ data, hasPriority = priority, alt }) => {
    return data
      ? (
        <Image
          src={data.src}
          alt={alt}
          sizes="100vw"
          fill
          priority={hasPriority}
          placeholder="blur"
          blurDataURL={data.base64 || ''}
        />
        )
      : null;
  };
  return desktop?.responsiveImage
    || tablet?.responsiveImage
    || mobile?.responsiveImage
    ? (
      <>
        {mobile?.responsiveImage && (
          <MobileImage
            $breakpointMax={
            tablet?.responsiveImage
              ? `${breakpointTablet}`
              : `${breakpointDesktop}`
          }
          >
            <ImageElement data={mobile.responsiveImage} alt={mobile.alt || ''} />
          </MobileImage>
        )}

        {tablet?.responsiveImage && (
          <TabletImage
            $breakpointMin={`${breakpointTablet}`}
            $breakpointMax={`${breakpointDesktop}`}
          >
            <ImageElement
              data={tablet.responsiveImage}
              alt={tablet.alt || ''}
              hasPriority={false}
            />
          </TabletImage>
        )}
        {desktop?.responsiveImage && (
          <DesktopImage
            $breakpointMin={`${breakpointDesktop}`}
            $force={!mobile?.responsiveImage && !tablet?.responsiveImage}
          >
            <ImageElement
              data={desktop.responsiveImage}
              alt={desktop.alt || ''}
              hasPriority={!mobile?.responsiveImage && !tablet?.responsiveImage}
            />
          </DesktopImage>
        )}
      </>
      )
    : null;
};
